import { postRequest } from 'api/base';

export const describe = async () => {
	try {
		const response = await postRequest('/service/herders/describe/all');

		return response;
	} catch(err){
		console.log('err',err);			
	}
};

export const start = async (id: string) => {
	try {
		const response = await postRequest('/service/herders/start', { id });

		return response;
	} catch(err){
		console.log('err',err);			
	}
};

export const stop = async (id: string) => {
	try {
		const response = await postRequest('/service/herders/stop', { id });

		return response;
	} catch(err){
		console.log('err',err);			
	}
};
// export const stopHerder = async ({ id }) => {
// 	try {
// 		const response = await postRequest('/service/herders/stop', { id });

// 		return response;
// 	} catch(err){
// 		console.log('err',err);			
// 	}
// };