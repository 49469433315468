import { bh1_ids, bh2_ids } from 'consts';

export interface FormattedBot {
	id: string,
	region: string,
	status: string,
	slug: string,
	imageName: string,
	imageId: number,
	ipAddress: string,
	createdAt: number,
	botnet: number,
}

export const formatBots = (rb0 = [], rb1 = [], rb2 = []): FormattedBot[] => {
	const merged = rb0.concat(rb1).concat(rb2);
	const newList = merged.map(((d: any) => {
		return ({ 
			id: d.id, 
			region:d?.region?.name, 
			status: d?.status, 
			slug:d?.size?.slug, 
			imageName: d.image.name, 
			imageId: d.image.id, 
			ipAddress: d?.networks['v4'][0]?.ip_address || 0,
			createdAt: d?.created_at,
			botnet: bh1_ids.includes(d.image.id) ? 1 : bh2_ids.includes(d.image.id) ? 2 : 3
		});
	}));

	return newList;
};
