/* eslint-disable react/prop-types */

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import { TableContent, TableHeader } from 'components';
import * as utils from 'components/Table/utils';
import { ACTIVE_BOTS_HEADERS } from 'consts';
import React, { useState } from 'react';

const BotnetTable = (props: any) => {
	const { selected, setSelected, data } = props;
	const classes = useStyles();
	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('calories');
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);

	const handleRequestSort = (event: any, property: any) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleSelectAllClick = () => {
		if (data?.length != selected?.length) {
			const newSelecteds = data.map((n: any) => n.id);
			setSelected(newSelecteds);
			return;
		}

		setSelected([]);
	};

	const handleClick = (event: any, name: any) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected: any[] = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1),
			);
		}

		setSelected(newSelected);
	};

	const handleChangePage = (event: any, newPage: any) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: any) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const dataCount = data ? data.length : 0;

	const isSelected = (name: any) => selected?.indexOf(name) !== -1;

	const emptyRows = rowsPerPage - Math.min(rowsPerPage, dataCount - page * rowsPerPage);

	const sortedData = utils.stableSort(data, utils.getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);


	return (
		<>
			<TableContainer>
				<Table
					className={classes.table}
					aria-labelledby="tableTitle"
					size="medium"
					aria-label="enhanced table">
					<TableHeader
						headerCells={ACTIVE_BOTS_HEADERS}
						classes={classes}
						numSelected={selected.length}
						order={order}
						orderBy={orderBy}
						onSelectAllClick={handleSelectAllClick}
						onRequestSort={handleRequestSort}
						rowCount={dataCount}
					/>
					<TableContent
						data={sortedData}
						tableRowClick={handleClick}
						emptyRowsCount={emptyRows}
						isRowSelected={isSelected}
					/>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[5, 10, 25, 100]}
				component="div"
				count={dataCount}
				rowsPerPage={rowsPerPage}
				page={page}
				onChangePage={handleChangePage}
				onChangeRowsPerPage={handleChangeRowsPerPage}
			/>
		</>
	);
};

const useStyles = makeStyles(() => ({
	table: {
		minWidth: 750,
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		width: 1,
	},
}));

export default BotnetTable;