import { getRequest, postRequest } from 'api/base';
import { FormattedBot } from 'api/formatters/bots';
import { RBS_LIMIT } from 'consts';
import { CreateBots, DeleteBot, RestartBot } from 'types';
import { formatBots } from '../formatters';

export const getBotsByTag = async (): 
	Promise<{ data: FormattedBot[], rb0Available: number, rb1Available: number, rb2Available: number }> => {
	try {
		const rRB0 = await getRequest('/service/bots/tag/rb0');
		const rRB1 = await getRequest('/service/bots/tag/rb1');
		const rRB2 = await getRequest('/service/bots/tag/rb2');
		const newList = formatBots(rRB0?.data?.data, rRB1?.data?.data, rRB2?.data?.data);

		return { 
			data: newList
			, rb0Available: RBS_LIMIT[0] - (rRB0?.data?.data?.length || 0)
			, rb1Available: RBS_LIMIT[1] - (rRB1?.data?.data?.length || 0)
			, rb2Available: RBS_LIMIT[2] - (rRB2?.data?.data?.length || 0)
		};
	} catch(err){
		console.log('err',err);			
		return {
			data: []
			, rb0Available: 0
			, rb1Available: 0
			, rb2Available: 0
		};
	}
};

export const createBots = async ({ quantity, region, size, bh, rb }: CreateBots) => {
	try { 
		const response = await postRequest('/service/bots/create', {
			quantity ,
			region,
			size,
			rb,
			bh
		});

		return response;
	} catch(err) {
		console.log('err',err);
	}
};

export const restartBot = async (bots: RestartBot[]) => {
	try { 
		const response = await postRequest('/service/bots/restart', { bots });

		return response;
	} catch(err) {
		console.log('err',err);
	}
};

export const deleteBot = async (bots: DeleteBot[]) => {
	try { 
		const response = await postRequest('/service/bots/delete', { bots });

		return response;
	} catch(err) {
		console.log('err',err);
	}
};

export const getRegions = async () => {
	try { 
		const response = await getRequest('/service/bots/regions');

		return response;
	} catch(err) {
		console.log('err',err);
	}
};

export const createDefaultBotnet = async () => {
	try { 
		const response = await postRequest('/service/bots/create/default');

		return response;
	} catch(err) {
		console.log('err',err);
	}
};
