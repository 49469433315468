import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { BotsAPI } from 'api';
import { TableToolbar, TabPanel } from 'components';
import { a11yProps } from 'components/Table/utils';
import { updateBots, useBots } from "hooks";
import {
	BotsTable,
	CreateCustomBot,
	CreateDefaultBotnet,
	Herders
} from 'pages';
import { useState } from 'react';
import { useStore } from 'react-hookstore';
import { toast } from 'react-toastify';
import { API_STORE, VIEW_STORE } from 'store';
import { MainWrapper, useStyles } from './style';

const Main = () => {
	const [{ bots }, setAPIStore] = useStore(API_STORE);
	const [{ selectedSplit }, setViewState] = useStore(VIEW_STORE);
	const [selected, setSelected] = useState([]);
	const classes = useStyles();
	useBots();
	updateBots();

	const restartItemsSelection = () => setSelected([]);

	const handleVerTabChange = (e: any, newValue: any) => {
		//@ts-ignore
		setViewState((pv: any) => ({ ...pv, selectedSplit: newValue }));
	};

	const restartSelectedItems = async () => {
		try {
			const botsToRestart = selected.map((s: any) => {
				const bot = bots.find((b: any) => b.id === s);
				return { imageId: bot.imageId, botId: bot.id };
			});
			const response = await BotsAPI.restartBot(botsToRestart);
			toast.success('Restarted Bot(s) Successfully');
			setTimeout(
				() =>
					//@ts-ignore
					setAPIStore((pv: any) => ({
						...pv,
						refetchAllDroplets: true,
					})),
				2500
			);
			restartItemsSelection();
		} catch (err) {
			console.log('err', err);
		}
	};

	const deleteSelectedItems = async () => {
		try {
			const botsToDelete = selected.map((s: any) => {
				const bot = bots.find((b: any) => b.id === s);
				return { imageId: bot.imageId, botId: bot.id };
			});
			const response = await BotsAPI.deleteBot(botsToDelete);
			toast.success('Deleted Bot(s) Successfully');
			setTimeout(
				() =>
					//@ts-ignore
					setAPIStore((pv: any) => ({
						...pv,
						refetchAllDroplets: true,
					})),
				2500
			);
			restartItemsSelection();
		} catch (err) {
			console.log('err', err);
		}
	};

	return (
		<MainWrapper>
			{selectedSplit === 0 && (
				<div
					style={{
						gridRow: '1',
						gridColumn: '2/4',
						padding: '10px',
					}}>
					<TableToolbar
						deleteSelected={deleteSelectedItems}
						restartSelected={restartSelectedItems}
						numSelected={selected.length}
						isRowSelected
					/>
				</div>
			)}
			<div style={{ gridRow: '1/3', gridColumn: '1' }}>
				<Tabs
					orientation='vertical'
					variant='scrollable'
					value={selectedSplit}
					onChange={handleVerTabChange}
					aria-label='Vertical tabs example'
					className={classes.tabs}>
					<Tab label='Active Bots' {...a11yProps(0)} />
					<Tab label='Create Default Botnet' {...a11yProps(1)} />
					<Tab label='Create Custom Botnet' {...a11yProps(2)} />
					<Tab label='Herders' {...a11yProps(3)} />
				</Tabs>
			</div>
			<div style={{ gridColumn: '2/4', gridRow: '2' }}>
				<TabPanel value={selectedSplit} index={0}>
					<BotsTable selected={selected} setSelected={setSelected} />
				</TabPanel>
				<TabPanel value={selectedSplit} index={2}>
					<CreateCustomBot />
				</TabPanel>
				<TabPanel value={selectedSplit} index={1}>
					<CreateDefaultBotnet />
				</TabPanel>
				<TabPanel value={selectedSplit} index={3}>
					<Herders />
				</TabPanel>
			</div>
		</MainWrapper>
	);
};

export default Main;

// const restartSelectItems = async () => {
// 	try {
// 		await Promise.all(selected.map(i => BotsAPI.restartBot({id: i})));
// 		toast.success('Restarted Bot(s) Successfully');
// 		//@ts-ignore
// 		setTimeout(() => setAPIStore((pv: any) => ({...pv, refetchAllDroplets: true})), 2500);
// 		restartItemsSelection();
// 	} catch(err) {
// 		console.log('err',err);
// 	}
// };
