import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import { BotsAPI } from 'api';
import { CircularLoading, SelectInput } from 'components';
import { useRegions } from 'hooks';
import { useState } from 'react';
import { useStore } from 'react-hookstore';
import { toast } from 'react-toastify';
import { API_STORE } from 'store';
import { APIStore } from 'types';
import { SC, useStyles } from './style';

interface GetAvailableRBInput {
	quantity: number;
	rb0: number;
	rb1: number;
	rb2: number;
}


const getAvailableRB = ({ quantity, rb0, rb1, rb2 }: GetAvailableRBInput) => {
	if (rb0 >= quantity) {
		return 0;
	} else if (rb1 >= quantity) {
		return 1;
	} else if (rb2 >= quantity) {
		return 2;
	} else {
		return -1;
	}
};

const CreateBot = () => {
	const [data, setAPIStore] = useStore<APIStore>(API_STORE);
	const [isLoading, setIsLoading] = useState(false);
	const classes = useStyles();
	const regions = useRegions();
	const onCreate = async ({ region, size, quantity, botnet }: any) => {
		const rb = getAvailableRB({
			quantity,
			rb0: data.rb0Available,
			rb1: data.rb1Available,
			rb2: data.rb2Available,
		});
		if(rb === -1) {
			toast.warn(`Not Enough Space! Space Left: ${data.rb0Available} + ${data.rb1Available} + ${data.rb2Available}`)
			return;
		}
		if (!region || !size || !quantity || !botnet) {
			toast.warn(
				'One Or More of Region, Size, Quantity or Botnet Are Not Configured!'
			);
			return;
		}

		try {
			setIsLoading(true);
			const response = await BotsAPI.createBots({
				region,
				size,
				quantity,
				rb,
				//@ts-ignore
				bh: Number(botnet),
			});
			if (
				response.status === 200 &&
				Number(response?.data?.data?.successCount) === Number(quantity)
			) {
				toast.success('Created Bot(s) Successfully');
				setTimeout(() => {
					setIsLoading(false);
					//@ts-ignore
					setAPIStore((pv: APIStore) => ({
						...pv,
						refetchAllDroplets: true,
					}));
				}, (4000));
			} else {
				setIsLoading(false);
				toast.error(
					'Something went wrong! please try with different configuration!'
				);
			}
		} catch (err) {
			console.log('err', err);
		}
	};

	return (
		<SC.CustomBotTableWrapper>
			<TableContainer component={Paper} style={{ maxHeight: 480 }}>
				<Table
					stickyHeader
					className={classes.table}
					aria-label="Bots Table">
					<TableHead>
						<TableRow>
							<TableCell>Region</TableCell>
							<TableCell align="left">Botnet</TableCell>
							<TableCell align="left">Size</TableCell>
							<TableCell align="left">Quantity</TableCell>
							<TableCell align="left"></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{regions
							.sort((a: any, b: any) =>
								a.name.toUpperCase() < b.name.toUpperCase()
									? -1
									: a.name.toUpperCase() >
									  b.name.toUpperCase()
									? 1
									: 0
							)
							.map((row: any) => (
								<CreateBotTableBody
									key={row.name}
									row={row}
									onCreate={onCreate}
									isLoading={isLoading}
								/>
							))}
					</TableBody>
				</Table>
			</TableContainer>
		</SC.CustomBotTableWrapper>
	);
};

const CreateBotTableBody = (props: any) => {
	const [selectedSize, setSelectedSize] = useState('');
	const [selectedBotnet, setSelectedBotnet] = useState('');
	const [quantity, setQuantity] = useState(1);
	const { row, onCreate, isLoading } = props;

	return (
		<TableRow key={row.name}>
			<TableCell component="th" scope="row">
				{row.name}
			</TableCell>
			<TableCell align="left">
				<SelectInput
					label="Select"
					required={true}
					options={[
						{ value: "1", name: "1" },
						{ value: "2", name: "2" },
						{ value: "3", name: "3" },
					]}
					inputValue={selectedBotnet}
					onChange={(e: any) => {
						setSelectedBotnet(e.target.value);
					}}
				/>
			</TableCell>
			<TableCell align="left">
				<SelectInput
					label="Select"
					required={true}
					options={[
						{ value: "small", name: "Small" },
						{ value: "medium", name: "Medium" },
						{ value: "large", name: "Large" },
						{ value: "xlarge", name: "Extra Large" },
					]}
					inputValue={selectedSize}
					onChange={(e: any) => setSelectedSize(e.target.value)}
				/>
			</TableCell>
			<TableCell align="left">
				<TextField
					id="outlined-basic"
					required={false}
					type="number"
					variant="filled"
					onChange={(e: any) =>
						e.target.value > 10
							? setQuantity(quantity)
							: setQuantity(e.target.value)
					}
					value={quantity}
				/>
			</TableCell>
			<TableCell align="left">
				<Button
					variant="contained"
					disabled={isLoading}
					color="primary"
					onClick={() =>
						onCreate({
							size: selectedSize,
							region: row.slug,
							quantity,
							botnet: selectedBotnet,
						})
					}>
					{isLoading ? <CircularLoading /> : "Create"}
				</Button>
			</TableCell>
		</TableRow>
	);
};

export default CreateBot;
