/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/prop-types */

import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { TabPanel } from 'components';
import { bh1_ids, bh2_ids, bh3_ids } from 'consts';
import { useEffect, useState } from 'react';
import { useStore } from 'react-hookstore';
import { API_STORE } from 'store';
import { BotnetTable, BotnetTabs } from './components';

const useStyles = makeStyles(() => ({
	root: {
		width: '100%',
	},
	paper: {
		width: '100%',
	},
}));

interface BotsTableProps {
	selected: any[];
	setSelected: Function;
}

const BotsTable = (props: BotsTableProps) => {
	const { selected, setSelected } = props;
	//@ts-ignore
	const [{bots}] = useStore(API_STORE);
	const classes = useStyles();
	const [tabsFilter, setTabsFilter] = useState(0);
	const [selectedBotnetBots, setSelectedBotnetBots] = useState([]);

	useEffect(() => {
		const botnetBots = 
			tabsFilter === 0 
				? bots?.filter((d:any) => bh1_ids.includes(d.imageId)) 
				: tabsFilter === 1 
					? bots?.filter((d:any) => bh2_ids.includes(d.imageId))
					: bots?.filter((d:any) => bh3_ids.includes(d.imageId));
		setSelectedBotnetBots(botnetBots);
	}, [tabsFilter, bots]);

	return (
		<div className={classes.root}>
			<Paper className={classes.paper}>
				<>
					<BotnetTabs tabsFilter={tabsFilter} setTabsFilter={setTabsFilter} />
					<TabPanel value={tabsFilter} index={0}>
						<BotnetTable data={selectedBotnetBots} selected={selected} setSelected={setSelected} />
					</TabPanel>
					<TabPanel value={tabsFilter} index={1}>
						<BotnetTable data={selectedBotnetBots} selected={selected} setSelected={setSelected}/>
					</TabPanel>
					<TabPanel value={tabsFilter} index={2}>
						<BotnetTable data={selectedBotnetBots} selected={selected} setSelected={setSelected}/>
					</TabPanel>
				</>
			</Paper>
		</div>
	);
};

export default BotsTable;
