import Checkbox from '@material-ui/core/Checkbox';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { SLUGS } from 'consts';

const TableContent = (props: any) => {
	const {data, tableRowClick, emptyRowsCount, isRowSelected} = props;

	return (
		<TableBody>
			{data.map((row: any, index: any) => {
				const isItemSelected = isRowSelected(row.id);
				const labelId = `checkbox-${index}`;
				const size = SLUGS.find(s => s.value === row.slug);

				return (
					<TableRow
						hover
						onClick={(event) => tableRowClick(event, row.id)}
						role="checkbox"
						aria-checked={isItemSelected}
						tabIndex={-1}
						key={row.id}
						selected={isItemSelected}
					>
						<TableCell padding="checkbox">
							<Checkbox
								checked={isItemSelected}
								inputProps={{ 'aria-labelledby': labelId }}
							/>
						</TableCell>
						<TableCell component="th" id={labelId} scope="row" padding="none">
							{row.region}
						</TableCell>
						<TableCell align="left">{row.status}</TableCell>
						<TableCell align="left">{row.ipAddress}</TableCell>
						<TableCell align="left">{size && size.name}</TableCell>
					</TableRow>
				);
			})}
			{emptyRowsCount > 0 && (
				<TableRow style={{ height: (53) * emptyRowsCount }}>
					<TableCell colSpan={6} />
				</TableRow>
			)}
		</TableBody>
	);
};

export default TableContent;