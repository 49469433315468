import { Button } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import { HerdersAPI } from 'api';
import React from 'react';
import { useEffect } from 'react';
import { isHerderOn, MapHerder } from 'types';

const Herders = () => {
	const [instances, setInstances] = React.useState([]);
	const [refresh, setRefresh] = React.useState(false);

	const startInstance = async (id: string) => {
		const response = await HerdersAPI.start(id);
    console.log("🚀 ~ file: index.tsx ~ line 13 ~ startInstance ~ response", response)
		setRefresh(true);
	}

	const stopInstance = async (id: string) => {
		const response = await HerdersAPI.stop(id);
    console.log("🚀 ~ file: index.tsx ~ line 13 ~ startInstance ~ response", response)
		setRefresh(true);
	}

	const describeInstances = async () => {
		const response = await HerdersAPI.describe();
		const instances = response?.data?.data?.instances?.map(
			(i: any) => ({
				id: MapHerder.indexOf(i?.id),
				state: i.state,
			})
		);
		console.log("🚀 ~ file: index.tsx ~ line 28 ~ handler ~ instances", instances)
		setInstances(instances || []);
	};

	useEffect(() => {
		if (refresh) {
			describeInstances();
			setRefresh(false);
		}
		if (refresh) {
			setTimeout(() => {
				describeInstances();
				setRefresh(false);
			}, 15000)
		}
	}, [refresh]);

	useEffect(() => {
		describeInstances();
	}, []);

	return (
		<div>
			<div style={{ display: "flex", justifyContent: "end" }}>
				<Button onClick={() => setRefresh(true)}> Refresh </Button>
			</div>
			<div
				style={{
					display: "grid",
					gridTemplateColumns: "1fr 1fr 1fr 1fr",
				}}>
				{instances?.map((i: { id: string; state: any }) => {
					return (
						<div key={i.id}>
							<div>{"Herder: " + (i.id + 1)}</div>
							<div>{"State: " + i.state.Name}</div>
							<Switch
								checked={Boolean(isHerderOn[i?.state?.Code])}
								onChange={() =>
									i.state.Code === 16
										? stopInstance(i.id)
										: startInstance(i.id)
								}
								disabled={
									i.state.Code === 0 || i.state.Code === 64
								}
								color="primary"
								name={"Herder" + i?.id}
								inputProps={{
									"aria-label": "primary checkbox",
								}}
							/>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default Herders;