import axios from 'axios';
import { getUserAccessToken } from 'services/aws';

// const CORE_API_URL = 'https://container-service-1.lr879uogqunb8.eu-west-1.cs.amazonlightsail.com/';
const CORE_API_URL = process.env.REACT_APP_CORE_API_URL;

const createAxiosInstance = async () => {
	const userToken = await getUserAccessToken();
	const httpRequest = axios.create({
		baseURL: CORE_API_URL,
		headers: {
			'Authorization': `Bearer ${userToken}` 
		}
	});

	return httpRequest;
};

const _getRequest = (query) => async () => { 
	const http = await createAxiosInstance();

	return http.get(query); 
};

const _postRequest = (path, body) => async () => { 
	const http = await createAxiosInstance();

	return http.post(path, body); 
};

// const _postRequest = (path, body) => 
// 	() => createAxiosInstance().post(path, body);
const _deleteRequest = (path, body) => 
	() => createAxiosInstance().delete(path, body);

export const getRequest = (query) => 
	ErrorHandler(_getRequest(query));
export const postRequest = (path, body) => 
	ErrorHandler(_postRequest(path, body));
export const deleteRequest = (path, body) => 
	ErrorHandler(_deleteRequest(path, body));

const ErrorHandler = async (requestHandler) => {
	try {
		const response  = await requestHandler();

		return response;
	} catch (err) {
		// const readbleError = err.toJSON().message;
		console.log(`Http Request Error: ${err}`);

		return err;
	}
};
