export const SLUGS = [
	{
		value: 's-4vcpu-8gb',
		name: 'small'
	},
	{
		value: 's-8vcpu-16gb',
		name: 'medium',
	},
	{
		value: 'g-8vcpu-32gb',
		name: 'large'
	},
	{
		value: 'c-16',
		name: 'extra large'
	}
];

export const bh1_ids = [114871092, 114871058, 114871042];
export const bh2_ids = [114871295, 114871252, 114871273];
export const bh3_ids = [114871413, 114871380, 114871346];

export const DEFAULT_BOTNET_RBS_COUNT = {
	0: 36,
	1: 57,
	2: 7
};

export const ACTIVE_BOTS_HEADERS = [
	{ id: 'region', numeric: false, disablePadding: true, label: 'Region' },
	{ id: 'status', numeric: false, disablePadding: true, label: 'Status' },
	{ id: 'ipaddress', numeric: false, disablePadding: true, label: 'IP Address' },
	{ id: 'size', numeric: true, disablePadding: true, label: 'Size' },
];

/**AWS COGNITO ERRORS */
export const NEW_PASSWORD_REQUIRED_ERROR = 'newPasswordRequiredError';
export const WRONG_CREDS_ERROR = 'Incorrect username or password.';
export const INVALID_PASSWORD = 'InvalidPasswordException';

export const RBS_LIMIT = {
	0: 40,
	1: 60,
	2: 60
};
